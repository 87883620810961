import { redirect, type ActionFunctionArgs } from '@remix-run/node';
import {
  postLogin, 
  type LoginPayload,
  type VerificationPayload,
  SendAuthRequestParams
} from '@entrycall/lib-api-server';
import { handleError } from '../handleError.server';
import { validateResponse } from '../validateResponse.server';
import { URL } from 'url';

const EMAIL_PARAM = 'email';
const PHONE_PARAM = 'phone';
const AUTH_OTP_ENDPOINT = '/auth/otp';

enum HttpMethod {
  POST = 'post',
  GET = 'get',
}

type LoginMethod = 'email' | 'phone';

interface LoginData {
  identifier: string;
  method: LoginMethod;
}

interface ApiConfig {
  baseURL: string;
  method: HttpMethod;
  endpoint: string;
  urlPath: string;
}

export async function authAction({
  request,
}: ActionFunctionArgs): Promise<Response> {
  const apiConfig: ApiConfig = {
    method: HttpMethod.POST,
    baseURL: process.env.API_VERIFY_URL || '',
    urlPath: '/auth/verification',
    endpoint: AUTH_OTP_ENDPOINT,
  };

  console.log('Auth action initiated', apiConfig);

  const url = new URL(request.url);
  const resend = url.searchParams.get('resend') === 'true';

  console.log('Request URL:', request.url);
  console.log('Resend flag:', resend);

  try {
    if (resend) {
      return await resendVerificationCode(url, apiConfig);
    } else {
      const formData = await request.formData();
      console.log('Form data:', Object.fromEntries(formData));
      const loginData = validateLoginData(formData);
      console.log('Validated login data:', loginData);
      return await handleLoginByType(loginData, apiConfig);
    }
  } catch (error: unknown) {
    console.error('Error in auth action:', error);
    const { error: errorMessage, details } = handleError(error);
    return new Response(JSON.stringify({ error: errorMessage, details }), {
      status: 400,
      headers: { 'Content-Type': 'application/json' },
    });
  }
}

function validateLoginData(formData: FormData): LoginData {
  const email = formData.get(EMAIL_PARAM);
  const phone = formData.get(PHONE_PARAM);

  console.log('Validating login data:', { email, phone });

  if (email && typeof email === 'string') {
    return { identifier: email, method: 'email' };
  } else if (phone && typeof phone === 'string') {
    return { identifier: formatPhoneNumber(phone), method: 'phone' };
  }

  throw new Error('Invalid login data provided');
}

async function handleLoginByType(
  loginData: LoginData,
  apiConfig: ApiConfig,
): Promise<Response> {
  const { identifier, method: loginMethod } = loginData;
  const { urlPath } = apiConfig;
  const encodedIdentifier = encodeURIComponent(identifier);
  const redirectUrl = `${urlPath}?${loginMethod}=${encodedIdentifier}`;

  console.log('Handling login', { loginMethod, identifier, redirectUrl });

  const payload: LoginPayload = { loginId: identifier };
  console.log('Payload:', payload);

  try {
    const response = await postLogin(payload); // Send only the payload
    console.log('API response:', response.data);
    validateResponse(response);
  } catch (error) {
    console.error('API request error:', error);
    if (error instanceof Error) {
      console.error('Error message:', error.message);
      console.error('Full error object:', JSON.stringify(error, null, 2));
    }
    throw error;
  }

  return redirect(redirectUrl);
}

function formatPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/[\s\-()]/g, '');
}

async function resendVerificationCode(
  url: URL,
  apiConfig: ApiConfig,
): Promise<Response> {
  try {
    const email = url.searchParams.get('email');
    const method = url.searchParams.get('method');
    const data = url.searchParams.get('data');

    console.log('Resend verification code params:', { email, method, data });

    if (!method || !data) {
      throw new Error(
        'Invalid input: both method and data must be provided in the URL',
      );
    }

    const loginData: LoginData = {
      identifier: data,
      method: method as LoginMethod,
    };

    console.log('Resend verification code login data:', loginData);

    // Use the loginData to resend the verification code
    // Note: This might need to be adjusted based on your specific resend logic
    const payload: VerificationPayload = { loginId: loginData.identifier, verifyCode: '' };
    const loginParams: SendAuthRequestParams<VerificationPayload> = {
      payload,
      endpointKey: 'OTP'
    };
    const response = await postLogin(loginParams.payload);
    validateResponse(response);

    return redirect(`${apiConfig.urlPath}?${loginData.method}=${encodeURIComponent(loginData.identifier)}`);
  } catch (error) {
    console.error('Error in newLoginRequest:', error);
    const { error: errorMessage, details } = handleError(error);
    return new Response(JSON.stringify({ error: errorMessage, details }), {
      status: 400,
      headers: { 'Content-Type': 'application/json' },
    });
  }
}